'use client';

import { DynamicTextStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { DynamicTextRichTextRenderer } from './richTextRenderer';
import { Lora } from 'next/font/google';

const CHANGE_INTERVAL = 3000;

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokDynamicText: StoryblokReactComponent<DynamicTextStoryblok> = ({
  component: { values: valuesTxt, beforeText, accentColor },
}) => {
  const values = valuesTxt.split(',').map(title => title.trim());
  const [currentTitle, setCurrentTitle] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrentTitle((currentTitle + 1) % values.length);
    }, CHANGE_INTERVAL);
  }, [currentTitle, values.length]);

  if (values.length === 0) {
    return null;
  }

  return (
    <DynamicTextRichTextRenderer richText={beforeText}>
      <ul className="relative inline-block ml-1">
        {values.map((value, i) => (
          <li
            key={i}
            className={clsx(
              lora.className,
              'transition-opacity duration-500  whitespace-nowrap',
              i !== 0 && 'absolute left-0 top-0',
              i === currentTitle ? 'opacity-100' : 'opacity-0',
              accentColor === 'brandBlue' ? 'text-brandBlue-400' : 'text-elty-green',
            )}
          >
            {value}
          </li>
        ))}
      </ul>
    </DynamicTextRichTextRenderer>
  );
};
