'use client';

import { CardsCarouselStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { StandardCardsCarousel } from './standardCardsCarousel';
import { PlansCarousel } from './plansCarousel';
import { ReviewsCarousel } from './reviewsCarousel';
import { StoryblokStory } from 'storyblok-generate-ts';
import { CardsWithBackgroundCarousel } from './cardsWithBackgroundCarousel';
import { PeopleCarousel } from './peopleCarousel';
import clsx from 'clsx';
import { ContentSectionCarousel } from './contentSectionCarousel';

const TIME_TO_WAIT_BEFORE_DIRECTION_CHANGE_FOR_AUTOSCROLL = 3000;
const AMOUNT_OF_PIXELS_TO_AUTOSCROLL = 0.5;

type CardsCarouselProps = {
  autoScroll?: boolean;
  navigation?: 'dots' | 'arrows';
  carousel: CardsCarouselStoryblok['carousel'][number];
  story: StoryblokStory<StoryblokComponent>;
  navigationVisibility: { sm: boolean; md: boolean; lg: boolean };
};

export type CardsCarouselRenderer<T extends CardsCarouselStoryblok['carousel'][number]> = FunctionComponent<{
  carousel: T;
  story: StoryblokStory<StoryblokComponent>;
}>;

const cardsRenderers: {
  [K in CardsCarouselProps['carousel'] as K['component']]: CardsCarouselRenderer<K>;
} = {
  plansCarousel: PlansCarousel,
  reviewsCarousel: ReviewsCarousel,
  standardCardsCarousel: StandardCardsCarousel,
  cardsWithBackgroundCarousel: CardsWithBackgroundCarousel,
  peopleCarousel: PeopleCarousel,
  contentSectionCarousel: ContentSectionCarousel,
};

function getCarouselLength(carousel: CardsCarouselStoryblok['carousel'][number]) {
  switch (carousel.component) {
    case 'plansCarousel':
      return carousel.plans.length;
    case 'reviewsCarousel':
      return carousel.reviews.length;
    case 'standardCardsCarousel':
      return carousel.cards.length;
    case 'cardsWithBackgroundCarousel':
      return carousel.cards.length;
    case 'peopleCarousel':
      return carousel.cards.length;
    case 'contentSectionCarousel':
      return carousel.sections?.length ?? 0;
  }
}

export const CardsCarousel = ({ carousel, navigation, autoScroll, story, navigationVisibility }: CardsCarouselProps) => {
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const [canGoToNextCard, setCanGoToNextCard] = useState(true);
  const [canGoToPrevCard, setCanGoToPrevCard] = useState(false);

  const autoScrollDirection = useRef<'left' | 'right'>('right');
  const autoScrollWaitingSince = useRef<number>(0);
  const autoScrollWaiting = useRef<boolean>(false);
  const autoScrollHovering = useRef<boolean>(false);

  const [currentCard, setCurrentCard] = useState(0);

  const onMouseEnter = () => {
    if (!autoScroll) return;
    autoScrollHovering.current = true;
    const onWindowMouseMove = (e: MouseEvent) => {
      const container = cardsContainerRef.current;
      if (!container) {
        window.removeEventListener('mousemove', onWindowMouseMove);
        return;
      }
      const rect = container.getBoundingClientRect();
      if (e.clientX < rect.left || e.clientX > rect.right || e.clientY < rect.top || e.clientY > rect.bottom) {
        autoScrollHovering.current = false;
        window.removeEventListener('mousemove', onWindowMouseMove);
      }
    };
    window.addEventListener('mousemove', onWindowMouseMove);
  };

  // autoscroll
  useEffect(() => {
    if (!autoScroll) return;
    const container = cardsContainerRef.current;
    if (!container) return;

    const scroll = () => {
      if (autoScrollHovering.current) {
        requestAnimationFrame(scroll);
        return;
      }

      if (autoScrollWaiting.current) {
        if (Date.now() - autoScrollWaitingSince.current > TIME_TO_WAIT_BEFORE_DIRECTION_CHANGE_FOR_AUTOSCROLL) {
          autoScrollDirection.current = autoScrollDirection.current === 'left' ? 'right' : 'left';
          autoScrollWaiting.current = false;
        }
      } else {
        switch (autoScrollDirection.current) {
          case 'left':
            container.scrollLeft -= AMOUNT_OF_PIXELS_TO_AUTOSCROLL;
            if (container.scrollLeft <= 0) {
              autoScrollWaiting.current = true;
              autoScrollWaitingSince.current = Date.now();
            }
            break;
          case 'right':
            container.scrollLeft += AMOUNT_OF_PIXELS_TO_AUTOSCROLL;
            if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
              autoScrollWaiting.current = true;
              autoScrollWaitingSince.current = Date.now();
            }
            break;
        }
      }
      requestAnimationFrame(scroll);
    };

    scroll();
  }, [autoScroll]);

  const getCardWidth = () => {
    const cardContainer = cardsContainerRef.current;
    if (!cardContainer) return;
    const cardElement = cardContainer.children[0];
    return cardElement?.getBoundingClientRect()?.width;
  };

  const scrollTo = (position: 'next' | 'prev' | number) => {
    if (autoScroll) return;
    const cardsContainer = cardsContainerRef.current;
    const cardWidth = getCardWidth();
    if (!cardsContainer || !cardWidth) return;
    let scrollLeft = 0;
    if (typeof position === 'number') {
      const goingForward = position > currentCard;
      scrollLeft = goingForward ? cardWidth * position : cardWidth * (position - 1);
    } else {
      scrollLeft = position === 'next' ? cardWidth + cardsContainer.scrollLeft : cardsContainer.scrollLeft - cardWidth;
    }
    cardsContainer.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    onCardsContainerScroll();
  };

  const onCardsContainerScroll = () => {
    const cardsContainer = cardsContainerRef.current;
    const cardWidth = getCardWidth();
    if (!cardsContainer || !cardWidth) return;
    const scrollLeft = cardsContainer.scrollLeft;
    const maxScrollLeft = cardsContainer.scrollWidth - cardsContainer.clientWidth;
    const canGoToNextCard = scrollLeft < maxScrollLeft;
    const canGoToPrevCard = scrollLeft > 0;
    setCanGoToNextCard(canGoToNextCard);
    setCanGoToPrevCard(canGoToPrevCard);
    const currentCard = Math.round(scrollLeft / cardWidth);
    setCurrentCard(currentCard);
  };

  const CardsCarousel = cardsRenderers[carousel.component] as CardsCarouselRenderer<typeof carousel>;
  const shouldAlignCardsToStart =
    ['reviewsCarousel', 'contentSectionCarousel'].includes(carousel.component) || getCarouselLength(carousel) >= 3;

  if (!CardsCarousel) return null;

  return (
    <>
      <div
        ref={cardsContainerRef}
        className={clsx(
          'flex flex-row overflow-x-auto overflow-y-visible px-4 md:px-20 pb-8 gap-4 md:gap-8 pt-11 xl:px-4',
          shouldAlignCardsToStart ? 'lg:justify-start' : 'lg:justify-center',
        )}
        onScroll={onCardsContainerScroll}
        onMouseEnter={onMouseEnter}
      >
        <CardsCarousel story={story} carousel={carousel} />
      </div>
      {!autoScroll && navigation === 'arrows' && (
        <div
          className={clsx(
            'flex flex-row items-center justify-center gap-1.5',
            navigationVisibility.sm ? 'flex' : 'hidden',
            navigationVisibility.md ? 'md:flex' : 'md:hidden',
            navigationVisibility.lg ? 'lg:flex' : 'lg:hidden',
          )}
        >
          <button onClick={() => canGoToPrevCard && scrollTo('prev')}>
            <svg className="rotate-180" width="50" height="50" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15.7778" cy="15.179" r="15" transform="rotate(180 15.7778 15.179)" fill="#F4F8FF" />
              <rect width="12" height="12" transform="translate(10.0779 9.07159)" fill="#F4F8FF" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.218 14.8064C18.3645 14.9529 18.3645 15.1903 18.218 15.3368L14.468 19.0868C14.3216 19.2332 14.0842 19.2332 13.9377 19.0868C13.7913 18.9403 13.7913 18.7029 13.9377 18.5564L17.4226 15.0716L13.9377 11.5868C13.7913 11.4403 13.7913 11.2029 13.9377 11.0564C14.0842 10.91 14.3216 10.91 14.468 11.0564L18.218 14.8064Z"
                fill="#031323"
              />
            </svg>
          </button>
          <button onClick={() => canGoToNextCard && scrollTo('next')}>
            <svg width="50" height="50" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15.7778" cy="15.179" r="15" transform="rotate(180 15.7778 15.179)" fill="#F4F8FF" />
              <rect width="12" height="12" transform="translate(10.0779 9.07159)" fill="#F4F8FF" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.218 14.8064C18.3645 14.9529 18.3645 15.1903 18.218 15.3368L14.468 19.0868C14.3216 19.2332 14.0842 19.2332 13.9377 19.0868C13.7913 18.9403 13.7913 18.7029 13.9377 18.5564L17.4226 15.0716L13.9377 11.5868C13.7913 11.4403 13.7913 11.2029 13.9377 11.0564C14.0842 10.91 14.3216 10.91 14.468 11.0564L18.218 14.8064Z"
                fill="#031323"
              />
            </svg>
          </button>
        </div>
      )}
      {!autoScroll && navigation === 'dots' && (
        <div
          className={clsx(
            'flex-row justify-center gap-1.5',
            navigationVisibility.sm ? 'flex' : 'hidden',
            navigationVisibility.md ? 'md:flex' : 'md:hidden',
            navigationVisibility.lg ? 'lg:flex' : 'lg:hidden',
          )}
        >
          {Array.from({ length: getCarouselLength(carousel) }).map((_, i) => (
            <div
              key={i}
              className={`w-2 h-2 rounded-full ${currentCard === i ? 'bg-elty-blue' : 'bg-black/10'}`}
              onClick={() => scrollTo(i)}
            />
          ))}
        </div>
      )}
    </>
  );
};
