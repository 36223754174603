import { ReviewsCarouselStoryblok } from '@/types/types-storyblok';
import { CardsCarouselRenderer } from './cardsCarousel';
import { Review } from './review';

export const ReviewsCarousel: CardsCarouselRenderer<ReviewsCarouselStoryblok> = ({ carousel, story }) => {
  return (
    <>
      {carousel.reviews.map(review => (
        <Review story={story} key={review._uid} component={review} />
      ))}
    </>
  );
};
