import { CardsCarouselRenderer } from './cardsCarousel';
import { PeopleCarouselStoryblok } from '@/types/types-storyblok';
import { PeopleCarouselCard } from './peoplecarouselCard';

export const PeopleCarousel: CardsCarouselRenderer<PeopleCarouselStoryblok> = ({ carousel, story }) => {
  return (
    <>
      {carousel.cards.map(card => (
        <PeopleCarouselCard key={card._uid} story={story} component={card} />
      ))}
    </>
  );
};
