import { VerticalSpaceStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import clsx from 'clsx';
import { storyblokEditable } from '@storyblok/js';

export const StoryblokVerticalSpace: StoryblokReactComponent<VerticalSpaceStoryblok> = ({ component }) => {
  const { amount } = component;
  return (
    <div
      className={clsx(
        amount === '1.25' && 'h-5',
        amount === '2' && 'h-8',
        amount === '3' && 'h-12',
        amount === '4' && 'h-16',
        amount === '5' && 'h-20',
        amount === '6' && 'h-24',
        amount === '7' && 'h-28',
        amount === '8' && 'h-32',
      )}
      {...storyblokEditable(component)}
    />
  );
};
