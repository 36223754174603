import { AssetStoryblok } from '@/types/types-storyblok';
import { HeaderGlobalStyle } from './headerGlobalStyle';
import HeaderLogo from './logo';
import { PropsWithChildren } from 'react';

export const HeaderContainer = ({ logo, children }: PropsWithChildren<{ logo: AssetStoryblok }>) => {
  return (
    <>
      <HeaderGlobalStyle />

      {/* Filler div to avoid content being hidden by the header */}
      <div className="h-header" />

      <header className="fixed top-[var(--header-top-offset,0px)] w-screen px-4 xl:px-20 py-5  z-20 shadow-1 overflow-y-visible overflow-x-hidden bg-white">
        <div className="flex flex-row items-center justify-between xl:max-w-screen-xl xl:mx-auto">
          <HeaderLogo logo={logo} />
          {children}
        </div>
      </header>
    </>
  );
};
