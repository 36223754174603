import { ELTY_SITE_URL } from '@/routes';
import { AuthorStoryblok } from '@/types/types-storyblok';
import { Person } from 'schema-dts';
import { StoryblokStory } from 'storyblok-generate-ts';
import { getImageProps } from 'next/image';

export const makePersonJsonLdFromStoryblokAuthor = (author: StoryblokStory<AuthorStoryblok>): Person => {
  return {
    '@type': 'Person',
    name: author.content.name,
    image: getImageProps({ src: author.content.image.filename, alt: '', width: 100, height: 100 }).props.src,
    url: `${ELTY_SITE_URL}/guide/autori/${author.slug}`,
  };
};
