import { StandardCardsCarouselStoryblok } from '@/types/types-storyblok';
import { CardsCarouselRenderer } from './cardsCarousel';
import { Card } from './card';

export const StandardCardsCarousel: CardsCarouselRenderer<StandardCardsCarouselStoryblok> = ({ carousel, story }) => {
  return (
    <>
      {carousel.cards.map(card => (
        <Card story={story} key={card._uid} component={card} />
      ))}
    </>
  );
};
