import { TestimonialStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import Image from 'next/image';
import { getImageDimensions } from '@/storyblok/utils/asset';
import { render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import { Lora } from 'next/font/google';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

const lora = Lora({ subsets: ['latin'], weight: '400', style: 'italic' });

export const Review: StoryblokReactComponent<TestimonialStoryblok> = ({ component: review, story }) => {
  return (
    <div
      key={review._uid}
      className="w-full md:w-1/2 lg:w-1/3 lg:max-w-sm flex-shrink-0 rounded-xl bg-white shadow-1 flex flex-col gap-8 py-8 px-10 relative"
      {...storyblokEditable(review)}
    >
      <div className="absolute">
        <svg className="w-[26px] h-[22px]" viewBox="0 0 53 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M30.6451 44.2284V30.1482C30.6451 22.0866 32.5225 15.4606 36.2772 10.2703C40.1424 4.96948 45.7192 1.54606 53.0078 0V9.11072C49.474 9.99418 46.7131 11.6507 44.7253 14.0802C42.7375 16.3993 41.5228 19.2705 41.0811 22.694H49.6948V44.2284H30.6451ZM0 44.2284V30.1482C0 22.0866 1.87736 15.4606 5.63208 10.2703C9.49723 4.96948 15.0741 1.54606 22.3627 0V9.11072C18.8288 9.99418 16.068 11.6507 14.0802 14.0802C12.0924 16.3993 10.8776 19.2705 10.4359 22.694H19.0497V44.2284H0Z"
            fill="#0BBCA7"
            fillOpacity="0.5"
          />
        </svg>
      </div>
      <Image
        className="mx-auto w-[140px] h-[75px]"
        src={review.image.filename}
        alt={review.image.alt ?? ''}
        width={getImageDimensions(review.image).width}
        height={getImageDimensions(review.image).height}
      />
      <span className="text-center w-full text-xl">{review.name}</span>
      <div className={clsx(lora.className, 'text-text-body text-center')}>
        {render(review.testimonial, makeRichTextRenderOptions(story))}
      </div>
    </div>
  );
};
