'use client';

import { BookingCtaSmallStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Image from 'next/image';
import medicalServiceIcon from '@/assets/medical-services-icon.svg';
import locationMarkerIcon from '@/assets/location-marker-icon.svg';
import searchOutlineIcon from '@/assets/search-outline-icon.svg';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import { fireGTMEvent } from '@/gtm';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';

export const StoryblokBookingCtaSmall: StoryblokReactComponent<BookingCtaSmallStoryblok> = ({ component, story }) => {
  return (
    <div
      className="mx-4 shadow-1 rounded-3xl bg-white p-6 flex flex-col items-stretch gap-6 relative z-[2] border"
      {...storyblokEditable(component)}
    >
      <div className="flex flex-row gap-4">
        <Image src={searchOutlineIcon} alt="search icon" aria-hidden />
        <h2 className="text-xl lg:text-lg">
          {render(
            component.title,
            makeRichTextRenderOptions(story, {
              markResolvers: {
                [MARK_BOLD]: children => <strong className="font-medium">{children}</strong>,
              },
            }),
          )}
        </h2>
      </div>
      <p className="w-8/12 lg:w-full">Cerca la prestazione medica di cui hai bisogno.</p>
      <form
        className="flex flex-col gap-3 lg:flex-row lg:p-1 "
        action="/cerca"
        onSubmit={e => {
          const formData = new FormData(e.target as HTMLFormElement);
          fireGTMEvent('blog_click_città', { city: formData.get('location'), speciality: formData.get('service') });
        }}
      >
        <div className="flex flex-row flex-grow items-center bg-background-neutral rounded-lg relative">
          <Image className="absolute ml-5 flex-shrink-0" src={medicalServiceIcon} alt="icona servizi medici" aria-hidden="true" />
          <input
            defaultValue={component.speciality || component.manualSpeciality}
            name="service"
            required
            className="bg-background-neutral flex-grow pl-14 pr-5 rounded-lg py-3 min-w-0 w-0"
            placeholder="Es. Psicologo"
          />
        </div>
        <div className="flex flex-row flex-grow items-center bg-background-neutral rounded-lg relative">
          <Image className="absolute ml-5 flex-shrink-0" src={locationMarkerIcon} alt="icona indicatore di posizione" aria-hidden="true" />
          <input
            name="location"
            required
            className="bg-background-neutral flex-grow pl-14 pr-5 rounded-lg py-3 min-w-0 w-0"
            placeholder="Es. Milano"
          />
        </div>
        <button
          className="transition-colors py-3 bg-elty-green text-white rounded-lg lg:px-12 lg:py-3 hover:bg-brandGreen-600 focus:bg-brandGreen-600"
          type="submit"
          aria-label="Cerca"
        >
          <span>Cerca</span>
        </button>
      </form>
    </div>
  );
};
