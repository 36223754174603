import { PostStoryblok, SectionStoryblok } from '@/types/types-storyblok';
import { Article } from 'schema-dts';
import { makePersonJsonLdFromStoryblokAuthor } from './person';
import { StoryblokStory } from 'storyblok-generate-ts';
import { richTextToString } from '@/storyblok/utils/richText';

export const makeArticleJsonLdFromStoryblokPost = (post: StoryblokStory<PostStoryblok>): Article => {
  const author = post.content.author;

  const articleBody =
    post.content.body
      ?.filter((content): content is SectionStoryblok => content.component === 'section')
      .map(section => richTextToString(section.body))
      .join(' ') ?? '';

  const wordCount = articleBody.split(/\s+/).length;

  return {
    '@type': 'Article',
    accessMode: 'textual',
    author: !!author && typeof author !== 'string' ? makePersonJsonLdFromStoryblokAuthor(author) : undefined,
    image: post.content.image?.filename,
    headline: post.content.title,
    datePublished: post.published_at,
    dateModified: post.published_at,
    articleBody,
    wordCount,
  };
};
