import { PeopleCarouselCardStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import Image from 'next/image';
import { storyblokEditable } from '@storyblok/js';

export const PeopleCarouselCard: StoryblokReactComponent<PeopleCarouselCardStoryblok> = ({ component }) => {
  return (
    <div
      className="rounded-t-[100%] rounded-b-3xl shadow-lg shrink-0 w-full md:w-[calc(50%-1rem)] xl:w-[calc(25%-1.5rem)] aspect-[.95] relative overflow-hidden flex flex-col justify-end"
      {...storyblokEditable(component)}
    >
      <Image src={component.background.filename} alt={component.background.alt ?? ''} fill className="object-cover" />
      <div className="bg-white/90 rounded-lg relative z-10 mx-2 mb-4 p-3 grid grid-cols-[20px,1fr] gap-x-2">
        <div className="flex items-center justify-center">
          <Image src={component.icon.filename} alt={component.icon.alt ?? ''} width={16} height={16} />
        </div>
        <span className="font-medium">{component.title}</span>
        <span className="text-sm text-text-body col-start-2">{component.subtitle}</span>
      </div>
    </div>
  );
};
