import { algoliaClinicsIndex } from '@/algolia/client';
import { AlgoliaClinicV2 } from '@/algolia/types';
import { capitalizeWordsInString } from '@/utils/string';

export type SearchClinicAutocompleteResponse = Array<{ type: 'ambulatory' | 'service' | 'specialization'; label: string }>;

export const fetchClinicAutocomplete = async (query: string) => {
  const res = await algoliaClinicsIndex.search<AlgoliaClinicV2>(query);

  // const ambulatories = new Set<string>();
  const services = new Set<string>();
  // const specializations = new Set<string>();

  for (const item of res.hits) {
    // ambulatories.add(capitalizeWordsInString(item.name));

    if (item.specializations.__typename === 'AggregatorSpeciality' || 'label' in item.specializations) {
      services.add(capitalizeWordsInString(item.specializations.label));
      // const specialization = item.specializations.specialization;
      // if (specialization) {
      //   specializations.add(capitalizeWordsInString(specialization));
      // }
      // } else if (item.specializations.__typename === 'Specialization' || 'detail' in item.specializations) {
      // const service = item.specializations.detail?.service;
      // if (service) {
      //   services.add(capitalizeWordsInString(service));
      // }
      // const specialization = item.specializations.detail?.specialization;
      // if (specialization) {
      //   specializations.add(capitalizeWordsInString(specialization));
      // }
    }
  }

  return [
    ...Array.from(services).map(label => ({ label, type: 'service' as const })),
    // ...Array.from(specializations).map(label => ({ label, type: 'specialization' as const })),
    // ...Array.from(ambulatories).map(label => ({ label, type: 'ambulatory' as const })),
  ];
};
