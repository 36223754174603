import { PlansCarouselStoryblok } from '@/types/types-storyblok';
import { CardsCarouselRenderer } from './cardsCarousel';
import { Plan } from './plan';

export const PlansCarousel: CardsCarouselRenderer<PlansCarouselStoryblok> = ({ carousel, story }) => {
  return (
    <>
      {carousel.plans.map(card => (
        <Plan story={story} key={card._uid} component={card} />
      ))}
    </>
  );
};
