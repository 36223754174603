import { LogosStripStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { render } from 'storyblok-rich-text-react-renderer';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';

export const StoryblokLogosStripComponent: StoryblokReactComponent<LogosStripStoryblok> = ({ story, component }) => {
  return (
    <section className="bg-elty-blue text-white py-14 md:px-20 md:py-8" {...storyblokEditable(component)}>
      <div className="md:flex md:flex-row lg:gap-0 lg:justify-between max-w-section mx-auto">
        <div className="flex flex-col gap-5 mx-9 md:w-5/12 md:mx-0 md:flex-shrink-0 lg:w-auto">
          <h2 className="text-2xl text-center md:text-left lg:max-w-64">{component.title}</h2>
          <div className="text-elty-green text-center md:text-left w-2/3 mx-auto md:w-auto md:mx-0">
            {render(component.subtitle, makeRichTextRenderOptions(story))}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row md:items-center md:justify-end my-14 w-1/2 mx-auto gap-16 md:gap-8 lg:gap-16 md:w-7/12 md:my-0 md:flex-shrink-0 lg:flex-grow lg:w-auto">
          {component.logos.map(logo => {
            const { height, width } = getImageDimensions(logo);
            return (
              <Image
                key={logo.id}
                src={logo.filename}
                alt={logo.alt ?? ''}
                height={height}
                width={width}
                className="md:max-h-[26px] md:max-w-[104px] xl:max-h-[38px] xl:max-w-[200px] xl:flex-shrink object-contain"
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};
