import { CardsCarouselStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import { StoryblokDynamicText } from '../dynamicText';
import { CardsCarousel } from './cardsCarousel';
import { Lora } from 'next/font/google';
import clsx from 'clsx';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokCardsCarousel: StoryblokReactComponent<CardsCarouselStoryblok> = ({ component, story }) => {
  const [carousel] = component.carousel;
  if (!carousel) return null;
  const userDidntSelectNavigationVisibility = (component.navigationIndicatorVisiblity ?? []).length === 0;
  const navigationVisiblity = component.navigationIndicatorVisiblity ?? [];
  if (userDidntSelectNavigationVisibility) {
    navigationVisiblity.push('mobile');
    if (component.navigationIndicator === 'arrows') {
      navigationVisiblity.push('tablet');
    }
  }

  return (
    <section className={clsx(component.background === 'lightBlue' && 'bg-blue-50')} {...storyblokEditable(component)}>
      <div className="pt-12 md:pt-24 lg:pt-16 max-w-section xl:mx-auto">
        <h2
          className={clsx('text-3xl lg:text-4xl text-center px-4 md:px-20 xl:px-0', component.textDirection === 'left' && 'lg:text-left')}
        >
          {render(
            component.title,
            makeRichTextRenderOptions(story, {
              markResolvers: {
                [MARK_BOLD]: children => (
                  <span className={clsx(lora.className, component.accentColor === 'brandBlue' ? 'text-brandBlue-400' : 'text-elty-green')}>
                    {children}
                  </span>
                ),
              },
              blokResolvers: {
                dynamicText: component => {
                  return <StoryblokDynamicText story={story} component={component as any} />;
                },
              },
            }),
          )}
        </h2>
        {component.subtitle && (
          <div
            className={clsx(
              'text-center mt-6 px-4 md:px-20 xl:px-0 text-lg',
              component.textDirection === 'left' && 'lg:text-left',
              component.subtitleColor === 'black' ? 'text-black' : 'text-secondary',
            )}
          >
            {render(component.subtitle, makeRichTextRenderOptions(story))}
          </div>
        )}
        <CardsCarousel
          story={story}
          autoScroll={component.autoscroll}
          navigation={component.navigationIndicator && component.navigationIndicator !== 'none' ? component.navigationIndicator : undefined}
          carousel={carousel}
          navigationVisibility={{
            sm: navigationVisiblity.includes('mobile'),
            md: navigationVisiblity.includes('tablet'),
            lg: navigationVisiblity.includes('desktop'),
          }}
        />
      </div>
    </section>
  );
};
