import { RichtextStoryblok } from '@/types/types-storyblok';
import { JSXElementConstructor, PropsWithChildren, ReactNode } from 'react';

/**
 * This is a component that allows us to insert the dynamic part of the text inside at the end of the user provided richt text
 * For now it supports only doc, paragraph and text nodes.
 */
export const DynamicTextRichTextRenderer = ({ children, richText }: PropsWithChildren<{ richText?: RichtextStoryblok }>) => {
  return <NodeRenderer node={richText} dynamicText={children} />;
};

export const NodeRenderer = ({
  node,
  parentNode,
  dynamicText,
}: {
  node?: RichtextStoryblok;
  parentNode?: RichtextStoryblok;
  dynamicText: ReactNode;
}) => {
  if (!node) return <>{dynamicText}</>;
  const Component = renderer[node.type];
  if (!Component) {
    console.warn(`Component ${node.type} not found in renderer`);
    return null;
  }
  return <Component node={node} parentNode={parentNode} dynamicText={dynamicText} />;
};

const renderer: Record<
  string,
  JSXElementConstructor<{ node: RichtextStoryblok; parentNode?: RichtextStoryblok; dynamicText: ReactNode }>
> = {
  doc: ({ node, dynamicText }) => {
    return <>{node.content?.map((child, i) => <NodeRenderer key={i} node={child} parentNode={node} dynamicText={dynamicText} />)}</>;
  },
  paragraph: ({ node, parentNode, dynamicText }) => {
    const isLast = parentNode?.content?.[parentNode.content.length - 1] === node;
    return (
      <>
        {node.content?.map((child, i) => <NodeRenderer key={i} node={child} parentNode={node} dynamicText={dynamicText} />)}
        {isLast && dynamicText}
      </>
    );
  },
  text: ({ node }) => {
    return <>{node.text}</>;
  },
};
