import { BreadcrumbList } from 'schema-dts';

export const makeJsonLdBreadcrumbs = (breadcrumbs: { label: string; href?: string }[]): BreadcrumbList => ({
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: breadcrumb.label,
    ...(!!breadcrumb.href ? { item: breadcrumb.href } : {}),
  })),
});
