'use client';

import { PlansCarouselPlanStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import Image from 'next/image';
import Link from 'next/link';
import { render } from 'storyblok-rich-text-react-renderer';
import { useState } from 'react';
import clsx from 'clsx';
import { resolveWebLink } from '@/storyblok/utils/link';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

export const Plan: StoryblokReactComponent<PlansCarouselPlanStoryblok> = ({ component: plan, story }) => {
  const [open, setOpen] = useState(false);
  // secured by storyblok configuration
  const [callToAction] = plan.callToAction;
  if (!callToAction) return null;
  return (
    <div className="w-full lg:w-[calc(50%-1rem)] xl:w-[calc(33.33%-1rem)] flex-shrink-0 " {...storyblokEditable(plan)}>
      <div className="rounded-lg bg-white border border-black/5 shadow-1 py-10 px-6 flex flex-col gap-8 justify-start translate-y-0 hover:-translate-y-1 transition-transform group">
        <div className="flex flex-row gap-5">
          <Image src={plan.image.filename} className="object-contain" alt={plan.image.alt ?? ''} height={60} width={60} />
          <h3 className="flex flex-col">
            <span>{plan.title}</span>
            <span className="text-xl">{plan.price}</span>
          </h3>
        </div>
        <div className="text-text-body font-light">{render(plan.description, makeRichTextRenderOptions(story))}</div>
        <button
          className={clsx(
            'font-semibold text-xs flex flex-row justify-between items-center pb-3 -mb-11',
            open ? 'border-b border-[#C9CCD2]' : '',
          )}
          onClick={() => setOpen(!open)}
        >
          SCOPRI COSA INCLUDE
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx('transition-transform duration-500', open && 'rotate-180')}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.07086 7.29302C6.25839 7.10555 6.5127 7.00023 6.77786 7.00023C7.04303 7.00023 7.29733 7.10555 7.48486 7.29302L10.7779 10.586L14.0709 7.29302C14.1631 7.19751 14.2735 7.12133 14.3955 7.06892C14.5175 7.01651 14.6487 6.98892 14.7815 6.98777C14.9142 6.98662 15.0459 7.01192 15.1688 7.0622C15.2917 7.11248 15.4034 7.18673 15.4973 7.28062C15.5912 7.37452 15.6654 7.48617 15.7157 7.60907C15.766 7.73196 15.7913 7.86364 15.7901 7.99642C15.789 8.1292 15.7614 8.26042 15.709 8.38242C15.6566 8.50443 15.5804 8.61477 15.4849 8.70702L11.4849 12.707C11.2973 12.8945 11.043 12.9998 10.7779 12.9998C10.5127 12.9998 10.2584 12.8945 10.0709 12.707L6.07086 8.70702C5.88339 8.51949 5.77808 8.26518 5.77808 8.00002C5.77808 7.73486 5.88339 7.48055 6.07086 7.29302Z"
              fill="#0F3250"
            />
          </svg>
        </button>
        <ul className={clsx('transition-all overflow-y-hidden duration-500 flex flex-col gap-8 ', open ? 'max-h-[550px] pt-8' : 'max-h-0')}>
          {plan.bulletPoints.map(point => (
            <li key={point._uid} className="flex flex-row gap-4">
              <Image className="h-8 w-8" src={point.image.filename} alt={point.image.alt ?? ''} height={30} width={30} />
              <div className="font-medium">{render(point.content, makeRichTextRenderOptions(story))}</div>
            </li>
          ))}
          <li className="text-text-body">{plan.bottomText}</li>
        </ul>
        <Link
          className="py-4 px-14 text-center text-white bg-elty-blue text-lg font-medium rounded-lg transition-opacity hover:bg-primary-hover"
          href={resolveWebLink(callToAction.link, { component: plan, story, additional: { plan: plan.title } })}
        >
          {callToAction.label}
        </Link>
      </div>
    </div>
  );
};
