import { TestimonialStripStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import { storyblokEditable } from '@storyblok/js';

export const StoryblokTestimonialStrip: StoryblokReactComponent<TestimonialStripStoryblok> = ({ component }) => {
  const { title, subtitle, illustration, ratingImage } = component;
  const { height: illustrationHeight, width: illustrationWidth } = getImageDimensions(illustration);
  const { height: ratingImageHeight, width: ratingImageWidth } = getImageDimensions(ratingImage);
  return (
    <section
      className="relative px-6 bg-brandBlue-300 mx-4 rounded-2xl mt-20 pb-8 md:p-0 md:mx-20 md:gap-x-8 md:flex md:flex-row md:items-center max-w-section xl:mx-auto"
      {...storyblokEditable(component)}
    >
      <Image
        className="relative -top-20 md:top-0 md:col-start-1 md:row-start-1 md:row-span-2 md:origin-bottom-right md:scale-125 lg:scale-[130%] md:h-[280px] lg:h-[180px] xl:h-[200px] md:w-auto"
        src={illustration.filename}
        height={illustrationHeight}
        width={illustrationWidth}
        alt={illustration.alt ?? ''}
      />
      <div className="lg:flex lg:flex-row lg:items-center lg:pr-20 lg:gap-10 xl:flex-grow xl:justify-between">
        <div className="flex flex-col -mt-14 md:mt-0 md:col-start-2 md:row-start-1 md:pt-4 md:pr-8 lg:pt-0 lg:min-w-56 ">
          <span className="font-medium text-3xl text-center md:text-2xl md:text-left lg:text-2xl xl:text-3xl">{title}</span>
          <span className="font-normal text-center md:text-left mt-8 md:mt-2 lg:pr-0 lg:mt-3">{subtitle}</span>
        </div>
        <Image
          className="mt-10 md:mt-5 md:row-start-2 md:col-start-2 md:max-w-64 md:pb-2 "
          src={ratingImage.filename}
          height={ratingImageHeight}
          width={ratingImageWidth}
          alt={ratingImage.alt ?? ''}
        />
      </div>
    </section>
  );
};
