import { CardsWithBackgroundCarouselCardStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponentProps } from '../componentsMap';
import Image from 'next/image';
import { render } from 'storyblok-rich-text-react-renderer';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

export const CardWithBackground = ({
  component,
  story,
  withoutShadows,
}: StoryblokReactComponentProps<CardsWithBackgroundCarouselCardStoryblok> & { withoutShadows?: boolean }) => {
  return (
    <Link
      href={component.link ? resolveWebLink(component.link, { component, story }) : '#'}
      className="rounded-2xl bg-white border border-black/5 w-full lg:w-[calc(50%-1rem)] xl:w-[calc(33.33%-1rem)] flex-shrink-0 shadow-1 py-14 px-10 flex flex-col gap-8 justify-end group relative overflow-hidden aspect-[0.93] transition-transform hover:-translate-y-2"
      {...storyblokEditable(component)}
    >
      {component.background && (
        <Image src={component.background?.filename} alt={component.background?.alt ?? ''} layout="fill" objectFit="cover" fill />
      )}
      {withoutShadows !== true && (
        <div className="absolute inset-0 bg-[linear-gradient(196.64deg,rgba(37,41,49,0)_23.65%,rgba(37,41,49,0.7)_78.51%)] z-0" />
      )}
      <div className="z-10 flex flex-col gap-8 ">
        {component.title && (
          <div className="text-white text-left text-xl drop-shadow-md">{render(component.title, makeRichTextRenderOptions(story))}</div>
        )}
        {component.cta && (
          <span className="text-white text-left group-hover:translate-x-2 transition-transform drop-shadow-md flex flex-row items-center gap-2">
            {render(component.cta, makeRichTextRenderOptions(story))}
            <ArrowIcon />
          </span>
        )}
      </div>
    </Link>
  );
};

const ArrowIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" transform="rotate(180 10 10)" fill="#F4F8FF" />
      <rect width="8" height="8" transform="translate(6.19995 5.92871)" fill="#F4F8FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6267 9.75193C11.7244 9.84957 11.7244 10.0079 11.6267 10.1055L9.12673 12.6055C9.0291 12.7031 8.87081 12.7031 8.77317 12.6055C8.67554 12.5079 8.67554 12.3496 8.77317 12.2519L11.0964 9.92871L8.77317 7.60549C8.67554 7.50786 8.67554 7.34957 8.77317 7.25193C8.87081 7.1543 9.0291 7.1543 9.12673 7.25193L11.6267 9.75193Z"
        fill="#252931"
      />
    </svg>
  );
};
