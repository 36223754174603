import { StoryblokComponent } from '@/types/types-storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';

type StoryblokComponentsIdentifiers = StoryblokComponent['component'];
type StoryblokComponentsMap = {
  [SBComponent in StoryblokComponent as SBComponent['component']]: SBComponent;
};

type StoryblokComponentByIdentifier<Id extends StoryblokComponentsIdentifiers> = StoryblokComponentsMap[Id];

export const storyContentIsOfType = <Id extends StoryblokComponentsIdentifiers>(
  story: StoryblokStory<StoryblokComponent>,
  type: Id,
): story is StoryblokStory<StoryblokComponentByIdentifier<Id>> => story.content.component === type;
