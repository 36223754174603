import { AdBannerStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Image from 'next/image';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';
import { getImageDimensions } from '../utils/asset';

export const StoryblokAdBanner: StoryblokReactComponent<AdBannerStoryblok> = ({ component, story }) => {
  return (
    <Link href={resolveWebLink(component.link, { story, component })} target="_blank" rel="nofollow" className="mt-10 md:mt-12">
      <Image
        className="md:hidden w-full h-auto object-cover"
        src={component.mobileImage.filename}
        alt={component.mobileImage.alt ?? ''}
        {...getImageDimensions(component.mobileImage)}
      />
      <Image
        className="hidden md:block"
        src={component.desktopImage.filename}
        alt={component.desktopImage.alt ?? ''}
        {...getImageDimensions(component.desktopImage)}
      />
    </Link>
  );
};
