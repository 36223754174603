import { ScriptEmbedStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Script from 'next/script';

export const StoryblokScriptEmbed: StoryblokReactComponent<ScriptEmbedStoryblok> = ({ component }) => {
  return (
    <Script id={component.identifier} src={component.src}>
      {component.content}
    </Script>
  );
};
