import { RichtextTitleStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import { Lora } from 'next/font/google';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';
const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokRichtextTitle: StoryblokReactComponent<RichtextTitleStoryblok> = ({ component, story }) => {
  return (
    <h2 className="text-center px-4 md:px-20 py-7 lg:py-20 lg:max-w-screen-lg lg:mx-auto" {...storyblokEditable(component)}>
      <span className="text-3xl lg:text-5xl leading-tight lg:leading-tight">
        {render(
          component.top,
          makeRichTextRenderOptions(story, {
            markResolvers: {
              [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
            },
          }),
        )}
      </span>
      {component.bottom && (
        <span
          className={clsx(
            lora.className,
            'text-6xl lg:text-9xl inline-block mt-8',
            component.bottomColor === 'elty-green' && 'text-elty-green',
          )}
        >
          {component.bottom}
        </span>
      )}
    </h2>
  );
};
