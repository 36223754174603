import { clientEnv } from '@/env/client';
import { z } from 'zod';

export const MAPBOX_PLACES_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

const placesResponse = z.object({
  features: z.array(
    z.object({
      place_name_it: z.string(),
    }),
  ),
});

export const placesByLocationName = async (location: string) => {
  const response = await fetch(
    `${MAPBOX_PLACES_BASE_URL}/${location}.json?access_token=${clientEnv.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}&limit=10&language=it&country=it&types=place,address`,
  );
  const data = await response.json();
  const validationResult = placesResponse.safeParse(data);
  if (!validationResult.success) {
    console.error({
      message: 'Mapbox response has unexpected structure',
      error: validationResult.error.format(),
      data,
    });
    return { features: [] };
  }
  return validationResult.data;
};
