import { SmallCardWithImageStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import { StoryblokReactComponent } from '../componentsMap';
import { storyblokEditable } from '@storyblok/js';
import clsx from 'clsx';

export const StoryblokSmallCardWithImage: StoryblokReactComponent<SmallCardWithImageStoryblok> = ({ component: card }) => {
  return (
    <li
      key={card._uid}
      className={clsx(
        'flex-1 flex flex-row justify-center gap-5 max-w-xs mx-auto p-5',
        card.direction === 'vertical' && 'lg:flex-col lg:justify-start',
      )}
      {...storyblokEditable(card)}
    >
      <Image
        className={clsx('object-contain', card.size === 'medium' ? 'size-24' : 'size-16')}
        src={card.image.filename}
        alt={card.image.alt ?? ''}
        height={80}
        width={80}
      />
      <div className={clsx('flex flex-col gap-3', card.direction === 'vertical' && 'flex-grow')}>
        <h3 className={clsx('text-xl', card.direction === 'vertical' && '')}>{card.title}</h3>
        <p className="text-text-body">{card.content}</p>
      </div>
    </li>
  );
};
