import { ContentSectionCarouselStoryblok } from '@/types/types-storyblok';
import { CardsCarouselRenderer } from './cardsCarousel';
import { StoryblokComponent } from '../storyblokComponent';

export const ContentSectionCarousel: CardsCarouselRenderer<ContentSectionCarouselStoryblok> = ({ carousel, story }) => {
  return (
    <>
      {carousel.sections?.map(section => (
        <div
          key={section._uid}
          className="flex-shrink-0 w-[calc(100%-1rem)] px-2 [&>section]:mx-0 [&>section]:shadow-sm [&>section]:border "
        >
          <StoryblokComponent component={section} story={story} />
        </div>
      ))}
    </>
  );
};
