'use client';

import { CompareTableStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import clsx from 'clsx';
import { useState } from 'react';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';

export const StoryblokCompareTable: StoryblokReactComponent<CompareTableStoryblok> = ({ component }) => {
  const [highlightedColumn, highlightedRow] = useState<number | null>(null);

  return (
    <section className="max-w-section xl:mx-auto overflow-x-auto py-16 px-4 md:px-10 lg:px-4 xl:px-0">
      <div className="flex flex-col mx-auto w-10/12 md:w-8/12 lg:w-7/12 xl:w-5/12 gap-2 pb-12">
        {component.preTitle && <p className="text-primary-pressed font-medium text-lg text-center">{component.preTitle}</p>}
        {component.title && <h2 className="text-3xl text-center">{component.title}</h2>}
        {component.subtitle && <p className="text-neutral-lighter font-normal text-center mt-3">{component.subtitle}</p>}
      </div>
      {component.table && (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse md:table-fixed" cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                {component.table.thead.map((row, x) => (
                  <th key={row._uid} className={clsx('h-[1px]', x === 0 && 'sticky left-0')}>
                    <div
                      onMouseEnter={() => highlightedRow(x === 0 ? null : x)}
                      onMouseLeave={() => highlightedRow(null)}
                      className={clsx(
                        'px-2 md:px-8 py-4 bg-white h-full w-full border-x-2 border-t-2 rounded-t-xl transition-colors flex items-center justify-center',
                        x === highlightedColumn ? 'border-primary-pressed' : 'border-transparent',
                      )}
                    >
                      {x !== 0 && !!component.columnImages?.at(x - 1) ? (
                        <>
                          <Image
                            src={component.columnImages.at(x - 1)!.filename}
                            alt={component.columnImages.at(x - 1)?.alt ?? ''}
                            width={50}
                            height={50}
                            className={clsx(
                              'object-contain mx-auto max-w-7 max-h-7 md:max-w-10 md:max-h-10',
                              component.largeColumnImages?.at(x - 1) && 'md:hidden',
                            )}
                          />
                          {component.largeColumnImages?.at(x - 1) && (
                            <Image
                              src={component.largeColumnImages.at(x - 1)!.filename}
                              alt={component.largeColumnImages.at(x - 1)?.alt ?? ''}
                              {...getImageDimensions(component.largeColumnImages.at(x - 1)!)}
                              className="object-contain max-h-16 hidden md:block"
                            />
                          )}
                        </>
                      ) : (
                        <span>{row.value}</span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {component.table.tbody.map((row, y) => (
                <tr key={row._uid} className="group border-none">
                  {row.body.map((cell, x) => (
                    <td className={clsx('h-[1px] border-none', x === 0 && 'sticky left-0 max-w-28 md:max-w-none')} key={cell._uid}>
                      <div
                        onMouseEnter={() => highlightedRow(x === 0 ? null : x)}
                        onMouseLeave={() => highlightedRow(null)}
                        className={clsx(
                          'px-2 md:px-8 py-4 h-full bg-white group-odd:bg-neutral-hover flex  items-center border-x-2 transition-colors',
                          x === highlightedColumn ? 'border-primary-pressed' : 'border-transparent',
                          y === Number(component.table?.tbody.length) - 1 && 'rounded-b-xl border-b-2',
                          x === 0 ? 'justify-start' : 'justify-center',
                        )}
                      >
                        {x === 0 ? (
                          <span className="text-sm md:text-base text-left">{cell.value}</span>
                        ) : cell.value === 'Y' ? (
                          <AvailableIcon />
                        ) : (
                          <UnavailableIcon />
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

const AvailableIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4 md:w-5 md:h-5"
    aria-label="Si"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6125 1.36104C10.6812 0.574966 9.3188 0.574967 8.38749 1.36104L7.53932 2.07692C7.14349 2.41102 6.65365 2.61394 6.1375 2.65759L5.03155 2.75111C3.81717 2.85381 2.85381 3.81717 2.75111 5.03155L2.65759 6.1375C2.61394 6.65365 2.41102 7.14349 2.07692 7.53934L1.36104 8.38749C0.574966 9.3188 0.574967 10.6812 1.36104 11.6125L2.07692 12.4607C2.41102 12.8565 2.61394 13.3464 2.65759 13.8625L2.75111 14.9685C2.85381 16.1829 3.81717 17.1462 5.03155 17.2489L6.1375 17.3424C6.65365 17.3861 7.14349 17.589 7.53934 17.9231L8.38749 18.639C9.3188 19.425 10.6812 19.425 11.6125 18.639L12.4607 17.9231C12.8565 17.589 13.3464 17.3861 13.8625 17.3424L14.9685 17.2489C16.1829 17.1462 17.1462 16.1829 17.2489 14.9685L17.3424 13.8625C17.3861 13.3464 17.589 12.8565 17.9231 12.4607L18.639 11.6125C19.425 10.6812 19.425 9.3188 18.639 8.38749L17.9231 7.53932C17.589 7.14349 17.3861 6.65365 17.3424 6.1375L17.2489 5.03155C17.1462 3.81717 16.1829 2.85381 14.9685 2.75111L13.8625 2.65759C13.3464 2.61394 12.8565 2.41102 12.4607 2.07692L11.6125 1.36104ZM14.546 8.29555C14.9854 7.85621 14.9854 7.1439 14.546 6.70456C14.1067 6.26521 13.3944 6.26521 12.955 6.70456L8.75054 10.9091L7.04604 9.20456C6.6067 8.76521 5.89439 8.76521 5.45505 9.20456C5.0157 9.6439 5.0157 10.3562 5.45505 10.7956L7.95505 13.2955C8.39439 13.7349 9.1067 13.7349 9.54604 13.2955L14.546 8.29555Z"
      fill="#0BBCA7"
    />
  </svg>
);

const UnavailableIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4 md:w-5 md:h-5"
    aria-label="No"
  >
    <rect width="20" height="20" rx="10" fill="#FF5656" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46895 5.46882C5.67217 5.2656 6.00166 5.2656 6.20488 5.46882L10 9.26394L13.7951 5.46882C13.9983 5.2656 14.3278 5.2656 14.5311 5.46882C14.7343 5.67205 14.7343 6.00154 14.5311 6.20476L10.7359 9.99988L14.5311 13.795C14.7343 13.9982 14.7343 14.3277 14.5311 14.5309C14.3278 14.7342 13.9983 14.7342 13.7951 14.5309L10 10.7358L6.20488 14.5309C6.00166 14.7342 5.67217 14.7342 5.46895 14.5309C5.26572 14.3277 5.26572 13.9982 5.46895 13.795L9.26406 9.99988L5.46895 6.20476C5.26572 6.00154 5.26572 5.67205 5.46895 5.46882Z"
      fill="white"
    />
  </svg>
);
