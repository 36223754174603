import { PostsBanner } from '@/app/_components/blog/postsBanner';
import { getCmsStories } from '@/storyblok/client';
import { CategoryStoryblok, PostStoryblok } from '@/types/types-storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';

export const PostRelatedArticles = async ({
  category,
  post,
}: {
  post: StoryblokStory<PostStoryblok>;
  category: StoryblokStory<CategoryStoryblok>;
}) => {
  const relatedArticles = await getCmsStories<PostStoryblok>({
    filter_query: {
      component: { in: 'post' },
      categories: { any_in_array: category.uuid },
    },
    excluding_slugs: post.full_slug,
    per_page: 3,
    resolve_relations: ['post.categories'],
  });
  if (relatedArticles.data.stories.length === 0) return null;
  return (
    <PostsBanner
      title="Articoli correlati"
      subtitle="Scopri altri articoli per prenderti cura della tua salute..."
      posts={relatedArticles.data.stories}
    />
  );
};
