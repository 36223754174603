import { Thing, WithContext } from 'schema-dts';

export const withContext = <T extends Thing>(data: T): WithContext<T> => {
  if (typeof data === 'object') {
    return {
      '@context': 'https://schema.org',
      ...data,
    };
  }

  throw new Error('Data must be an object in order to add context to it.');
};
