import { StoryblokReactComponent } from './componentsMap';
import { EmptyHeaderStoryblok } from '@/types/types-storyblok';
import { HeaderContainer } from '@/app/_components/layout/header/container';
import { HeaderBanner } from '@/app/_components/layout/header/banner';

export const StoryblokEmptyHeader: StoryblokReactComponent<EmptyHeaderStoryblok> = ({ component, story }) => {
  return (
    <>
      <HeaderContainer logo={component.logo} />
      {component?.bannerEnabled && component?.bannerContent && <HeaderBanner story={story} bannerContent={component.bannerContent} />}
    </>
  );
};
