import { CardsWithBackgroundCarouselStoryblok } from '@/types/types-storyblok';
import { CardsCarouselRenderer } from './cardsCarousel';
import { CardWithBackground } from './cardWithBackground';

export const CardsWithBackgroundCarousel: CardsCarouselRenderer<CardsWithBackgroundCarouselStoryblok> = ({ carousel, story }) => {
  return (
    <>
      {carousel.cards.map(card => (
        <CardWithBackground story={story} key={card._uid} component={card} withoutShadows={carousel.withoutShadows} />
      ))}
    </>
  );
};
