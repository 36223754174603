import { ServiceCardStoryblok } from '@/types/types-storyblok';
import medicalServiceIcon from '@/assets/medical-services-icon.svg';
import locationMarkerIcon from '@/assets/location-marker-icon.svg';
import chevronRightIcon from '@/assets/service-card-chevron-right-icon.svg';
import Image from 'next/image';
import { StoryblokReactComponent } from '../componentsMap';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { storyblokEditable } from '@storyblok/js';

export const StoryblokServiceCard: StoryblokReactComponent<ServiceCardStoryblok> = ({ component: card, story }) => {
  if (!card.location || !card.service) return null;
  return (
    <li key={card._uid} className="flex-grow group" {...storyblokEditable(card)}>
      {card.location && (
        <Link
          href={
            card.link
              ? resolveWebLink(card.link, { component: card, story })
              : `/cerca?location=${encodeURIComponent(card.location?.toLowerCase())}&service=${encodeURIComponent(
                  card.service.toLowerCase(),
                )}`
          }
          className="flex flex-row items-center py-3 px-5 border border-white transition-colors group-focus:border-elty-green group-hover:border-elty-green rounded-xl bg-white shadow-1"
        >
          <div className="flex-grow flex flex-col gap-2">
            <div className="flex flex-row items-center gap-3">
              <Image src={medicalServiceIcon} alt="icona servizio medico" aria-hidden />
              <span className="font-medium" aria-label="prestazione">
                {card.service}
              </span>
            </div>
            <div className="flex flex-row items-center gap-3 text-text-body text-sm">
              <Image src={locationMarkerIcon} alt="icona posizione" aria-hidden />
              <span aria-label="posizione">{card.location}</span>
            </div>
          </div>
          <Image
            className="transition-transform translate-x-0 group-hover:translate-x-2"
            src={chevronRightIcon}
            alt="icona freccia destra"
            aria-hidden
          />
        </Link>
      )}
    </li>
  );
};
