import { InlineCallToActionStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import clsx from 'clsx';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';
import { storyblokEditable } from '@storyblok/js';

export const StoryblokInlineCTA: StoryblokReactComponent<InlineCallToActionStoryblok> = ({ component, story }) => (
  <div
    className={clsx(
      'flex flex-row px-4 md:px-10 xl:px-0 max-w-section xl:mx-auto',
      component.position === 'center' && 'justify-center',
      component.position === 'right' && 'justify-end',
      component.position === 'left' && 'justify-start',
    )}
    {...storyblokEditable(component)}
  >
    <Link
      className={clsx(
        'py-4 px-14 rounded-xl text-lg font-medium transition-colors text-white hover:bg-primary-hover focus:bg-primary-hover',
        component.color === 'brand-blue' && 'bg-elty-blue ',
        component.color === 'brand-green' && 'bg-elty-green hover:bg-secondary-hover focus:bg-secondary-hover',
      )}
      href={resolveWebLink(component.link, { component, story, additional: { cta: component.content } })}
    >
      {component.content}
    </Link>
  </div>
);
