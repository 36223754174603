import { CtaBannerStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import { DAVINCI_CDN } from '@/routes';
import clsx from 'clsx';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';

export const StoryblokCtaBanner: StoryblokReactComponent<CtaBannerStoryblok> = ({ component, story }) => {
  const [cta] = component.cta ?? [];
  const { background: colorScheme, borderRadius, mobileLayout, imageSize, imageMobile, imageTablet, imageDesktop, image } = component;
  const isInsidePost = story.content.component === 'post';
  const textIsBlack = ['roseWBlackText', 'lightBlueWBlackText', 'brandGreenWBlackText'].includes(colorScheme);
  const responsiveImages =
    !!imageMobile?.filename && !!imageTablet?.filename && !!imageDesktop?.filename
      ? { sm: imageMobile, md: imageTablet, lg: imageDesktop }
      : undefined;
  const hasImage = image?.filename || responsiveImages;

  return (
    <section
      className={clsx(
        (colorScheme === 'brandBlue' || colorScheme === '') && 'bg-elty-blue',
        colorScheme === 'brandGreen' && 'bg-elty-green',
        colorScheme === 'lightBlue' && 'bg-brandBlue-400',
        colorScheme === 'roseWBlackText' && 'bg-rose-100',
        colorScheme === 'lightBlueWBlackText' && 'bg-brandBlue-100',
        colorScheme === 'brandGreenWBlackText' && 'bg-brandGreen-200',
        borderRadius === 'rounded' && 'rounded-xl',
        isInsidePost && 'mx-4',
      )}
      {...storyblokEditable(component)}
    >
      <div
        className={clsx(
          'grid grid-cols-1 max-w-section xl:mx-auto',
          isInsidePost
            ? 'px-5 py-8 gap-y-4 lg:p-0 lg:gap-x-10 lg:gap-y-4 lg:grid-cols-[minmax(min-content,3fr),minmax(min-content,2.5fr)] lg:grid-rows-[repeat(3,minmax(0,min-content))]'
            : 'px-4 py-16 gap-y-10 md:p-0 md:gap-x-20 md:gap-y-4 md:grid-cols-[minmax(min-content,3fr),minmax(min-content,2fr)] md:grid-rows-[repeat(3,minmax(0,min-content))]',
        )}
      >
        <span
          className={clsx(
            'text-center flex flex-col justify-start',
            textIsBlack ? 'text-neutral-darker' : 'text-white',
            mobileLayout === 'pretitleOnTop' ? 'row-start-1' : 'row-start-2',
            isInsidePost
              ? 'font-semibold text-2xl md:w-8/12 mx-auto lg:w-full lg:text-left lg:text-3xl lg:leading-normal lg:col-start-1 lg:pt-10 lg:pl-14 lg:row-start-1'
              : 'md:text-left md:col-start-1 md:pt-12 md:pl-16 md:row-start-1',
          )}
        >
          {component.preTitle}
        </span>
        <h2
          className={clsx(
            'text-center leading-snug row-start-3',
            textIsBlack ? 'text-neutral-darker' : 'text-white',
            isInsidePost
              ? 'lg:text-left  lg:col-start-1 lg:row-start-2 lg:pl-14 lg:max-w-md'
              : 'text-2xl md:text-left md:text-3xl md:leading-normal md:col-start-1 md:row-start-2 md:pl-16 md:max-w-lg',
          )}
        >
          {render(component.title, makeRichTextRenderOptions(story))}
        </h2>
        {cta && cta.component === 'linkCallToAction' && (
          <div
            {...storyblokEditable(cta)}
            className={clsx(
              'row-start-4',
              isInsidePost
                ? 'lg:col-start-1 lg:row-start-3 lg:pb-10 lg:pl-14 lg:mt-4'
                : 'md:col-start-1 md:row-start-3 md:pb-12 md:pl-16 md:mt-4',
            )}
          >
            <Link
              href={resolveWebLink(cta.link, { component, story })}
              className={clsx(
                'text-center border border-transparent px-6 py-4 font-semibold rounded transition-colors duration-300 inline-block w-full',
                colorScheme === 'roseWBlackText' && 'bg-rose-400 hover:bg-rose-700',
                colorScheme === 'lightBlueWBlackText' && 'bg-brandBlue-500 hover:bg-brandBlue-700',
                colorScheme === 'brandGreenWBlackText' && 'bg-brandGreen-500 hover:bg-brandGreen-700',
                ['lightBlue', 'brandGreen', 'brandBlue', ''].includes(colorScheme) &&
                  'bg-white hover:bg-transparent hover:text-white hover:border-white',
                textIsBlack ? 'text-white' : 'text-elty-blue',
                isInsidePost ? 'lg:w-auto lg:text-left' : 'text-lg md:w-auto md:text-left',
              )}
            >
              {cta.label}
            </Link>
          </div>
        )}
        {cta && cta.component === 'appStoreButton' && (
          <div
            className={clsx(
              'flex flex-row justify-center gap-x-4 row-start-4',
              isInsidePost
                ? 'lg:justify-start lg:col-start-1 lg:pb-10 lg:pl-8 lg:mt-4'
                : 'md:justify-start md:col-start-1 md:pb-12 md:pl-16 md:mt-4',
            )}
            {...storyblokEditable(cta)}
          >
            <Link
              href="https://apps.apple.com/it/app/davincisalute/id1469087727"
              target="_blank"
              rel="noopener noreferrer"
              className="h-12"
            >
              <Image
                src={`${DAVINCI_CDN}/elty/download-on-app-store.png`}
                width={184}
                height={56}
                alt="Download on App Store"
                className="h-12 w-auto"
              />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.davincisalute.app"
              target="_blank"
              rel="noopener noreferrer"
              className="h-12"
            >
              <Image
                src={`${DAVINCI_CDN}/elty/download-on-google-play.png`}
                height={56}
                width={183}
                alt="Download on Google Play"
                className="h-12 w-auto"
              />
            </Link>
          </div>
        )}
        <div
          className={clsx(
            'flex flex-col justify-center items-center  overflow-hidden rounded-xl ',
            isInsidePost ? 'mx-0 lg:col-start-2 lg:row-start-1 lg:row-span-3' : 'mx-7 md:col-start-2 md:row-start-1 md:row-span-3',
            mobileLayout === 'pretitleOnTop' ? 'row-start-2' : 'row-start-1',
            imageSize === 'contained'
              ? isInsidePost
                ? 'lg:rounded-xl lg:my-10 lg:ml-0 lg:mr-8'
                : 'md:rounded-xl md:my-12 md:ml-0 md:mr-16'
              : isInsidePost
                ? borderRadius !== 'rounded'
                  ? 'lg:rounded-none lg:mx-0'
                  : 'lg:rounded-l-none lg:rounded-r-xl lg:mx-0'
                : borderRadius !== 'rounded'
                  ? 'md:rounded-none md:mx-0'
                  : 'md:rounded-l-none md:rounded-r-xl md:mx-0',
          )}
        >
          {image?.filename && !responsiveImages && (
            <Image
              className={clsx(
                'w-full aspect-[1.4] object-cover',
                isInsidePost ? 'lg:w-full lg:h-auto lg:aspect-auto' : 'md:w-full md:h-auto md:aspect-auto',
              )}
              src={image.filename}
              alt={image.alt ?? ''}
              {...getImageDimensions(image)}
            />
          )}
          {responsiveImages && (
            <>
              <Image
                className={clsx(
                  'w-full aspect-[1.4] object-cover md:hidden',
                  isInsidePost ? 'lg:h-full lg:aspect-auto' : 'md:h-full md:aspect-auto',
                )}
                src={responsiveImages.sm.filename}
                alt={responsiveImages.sm.alt ?? ''}
                {...getImageDimensions(responsiveImages.sm)}
              />
              <Image
                className={clsx(
                  'w-full aspect-[1.4] object-cover hidden md:inline-block lg:hidden',
                  !isInsidePost && 'md:h-full md:aspect-auto',
                )}
                src={responsiveImages.md.filename}
                alt={responsiveImages.md.alt ?? ''}
                {...getImageDimensions(responsiveImages.md)}
              />
              <Image
                className={clsx('w-full aspect-[1.4] object-cover hidden lg:inline-block lg:h-full lg:aspect-auto')}
                src={responsiveImages.lg.filename}
                alt={responsiveImages.lg.alt ?? ''}
                {...getImageDimensions(responsiveImages.lg)}
              />
            </>
          )}
          {!hasImage && (
            <div
              className={clsx('bg-text-body w-full aspect-[1.4]', isInsidePost ? 'lg:h-full lg:aspect-auto' : 'md:h-full md:aspect-auto')}
            />
          )}
        </div>
      </div>
    </section>
  );
};
