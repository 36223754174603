import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';
import { RichtextStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { StoryblokStory } from 'storyblok-generate-ts';
import { render, MARK_BOLD } from 'storyblok-rich-text-react-renderer';

export const HeaderBanner = ({ bannerContent, story }: { bannerContent: RichtextStoryblok; story: StoryblokStory<StoryblokComponent> }) => {
  return (
    <div className="w-screen text-white bg-brandBlue-400 p-4 lg:p-2 text-sm text-center z-10">
      {render(
        bannerContent,
        makeRichTextRenderOptions(story, {
          markResolvers: {
            [MARK_BOLD]: children => <span className="font-semibold">{children}</span>,
          },
        }),
      )}
    </div>
  );
};
