import { StoryblokComponent as StoryblokComponentType } from '@/types/types-storyblok';
import { StoryblokReactComponent, componentsMap } from './componentsMap';
import * as Sentry from '@sentry/nextjs';

export const StoryblokComponent: StoryblokReactComponent<StoryblokComponentType> = ({ component, story }) =>
  Sentry.withScope(scope => {
    const Element = componentsMap[component.component] as StoryblokReactComponent<StoryblokComponentType>;
    if (!Element) return null;
    scope.setContext('story', {
      id: story.id,
      slug: story.slug,
      full_slug: story.full_slug,
    });
    scope.setContext('component', {
      uid: component._uid,
      component: component.component,
    });
    return <Element story={story} component={component} />;
  });
