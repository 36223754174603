import { HomeHeroBannerRichtextWithCtaStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponentProps } from '../componentsMap';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { storyblokEditable } from '@storyblok/js';

export function StoryblokHomeHeroBannerRichTextWithCta({
  component,
  story,
}: StoryblokReactComponentProps<HomeHeroBannerRichtextWithCtaStoryblok>) {
  const { cta } = component;
  const { link, label } = cta?.[0] ?? {};
  if (!link || !label) return null;
  return (
    <div
      className="flex flex-row items-center justify-center h-0 mt-8 md:mt-0 md:h-auto lg:pl-20 xl:pl-0 xl:mx-auto xl:w-full xl:justify-start xl:max-w-screen-xl relative z-10"
      {...storyblokEditable(component)}
    >
      <Link
        className="bg-elty-blue text-white py-4 px-14 rounded-xl text-lg font-medium lg:top-0 transition-colors hover:bg-primary-hover focus:bg-primary-hover"
        href={resolveWebLink(link, { component, story })}
      >
        {label}
      </Link>
    </div>
  );
}
