declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const fireGTMEvent = (eventName: string, obj: any = {}) => {
  const dataLayer = window?.dataLayer;
  if (dataLayer) {
    dataLayer.push({ event: eventName, ...obj });
  } else {
    console.warn('dataLayer not found, tried to send event:', eventName, obj);
  }
};
