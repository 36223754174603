import React from 'react';

const RightChevronIcon = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49686 4.69064C5.66771 4.8615 5.66771 5.1385 5.49686 5.30936L1.12186 9.68436C0.951005 9.85521 0.673995 9.85521 0.503141 9.68436C0.332286 9.5135 0.332286 9.23649 0.503141 9.06564L4.56878 5L0.503141 0.934359C0.332287 0.763505 0.332287 0.486495 0.503141 0.315641C0.673995 0.144786 0.951005 0.144786 1.12186 0.315641L5.49686 4.69064Z"
      fill="currentColor"
    />
  </svg>
);

const Home = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.15468 1.08751C7.06925 1.00208 6.93075 1.00208 6.84532 1.08751L3.0625 4.87033V10.5954C3.0625 10.7162 3.16044 10.8141 3.28125 10.8141H5.25V8.40786C5.25 7.8038 5.73969 7.31411 6.34375 7.31411H7.65625C8.26031 7.31411 8.75 7.8038 8.75 8.40786V10.8141H10.7187C10.8396 10.8141 10.9375 10.7162 10.9375 10.5954V4.87033L7.15468 1.08751ZM11.8125 5.74533L12.3781 6.31097C12.549 6.48182 12.826 6.48182 12.9969 6.31097C13.1677 6.14011 13.1677 5.8631 12.9969 5.69225L7.7734 0.468789C7.34626 0.0416534 6.65374 0.0416536 6.2266 0.46879L1.00314 5.69225C0.832286 5.8631 0.832286 6.14011 1.00314 6.31097C1.174 6.48182 1.451 6.48182 1.62186 6.31097L2.1875 5.74533V10.5954C2.1875 11.1994 2.67719 11.6891 3.28125 11.6891H10.7187C11.3228 11.6891 11.8125 11.1994 11.8125 10.5954V5.74533ZM7.875 10.8141H6.125V8.40786C6.125 8.28705 6.22294 8.18911 6.34375 8.18911H7.65625C7.77706 8.18911 7.875 8.28705 7.875 8.40786V10.8141Z"
      fill="currentColor"
    />
  </svg>
);

export const ELBreadcrumb: React.FC<{ data: { href?: string; label: string; isHome?: boolean }[] }> = ({ data }) => {
  return (
    <div className="flex min-w-0 items-center gap-2 text-neutral-lighter">
      {data.map((item, index) => {
        let element = null;
        // If there is no href, render a span instead of a link (ex: last item in the breadcrumb)
        if (!item.href) element = <span className="line-clamp-1 flex-shrink-0">{item.label}</span>;
        // Home icon
        else if (item.isHome) {
          element = (
            <a href={item.href} className="text-primary" aria-label={item.label}>
              <Home />
            </a>
          );
        } else
          element = (
            <a className="line-clamp-1 flex text-ellipsis whitespace-nowrap underline" aria-label={item.label} href={item.href}>
              {item.label}
            </a>
          );
        return (
          <React.Fragment key={index}>
            {element}
            {index < data.length - 1 && <RightChevronIcon />}
          </React.Fragment>
        );
      })}
    </div>
  );
};
