'use client';
import { PostStoryblok, SectionStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import { titleToElementId } from './utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';

export const PostSummary: StoryblokReactComponent<PostStoryblok> = ({ component }) => {
  const indexes = useMemo(
    () =>
      component.body
        ?.filter((blok): blok is SectionStoryblok => blok.component === 'section')
        .flatMap(blok => blok.body.content ?? [])
        .filter(content => content.type === 'heading' && content.attrs?.level === 2)
        .flatMap(content => content.content ?? [])
        .map(content => content.text)
        .filter((text): text is string => typeof text === 'string' && text.length > 0) ?? [],
    [component.body],
  );

  const [open, setOpen] = useState(false);
  const [openHeight, setOpenHeight] = useState(0);
  const indexesListRef = useRef<HTMLOListElement>(null);

  const [visibleIndexes, setVisibleIndexes] = useState(new Set([0]));
  const firstVisibleIndex = Math.min(...Array.from(visibleIndexes));

  useEffect(() => {
    if (!indexesListRef.current) return;
    const height = indexesListRef.current.getBoundingClientRect().height;
    setOpenHeight(height);
  }, []);

  useEffect(() => {
    const elements = indexes
      .map(title => titleToElementId(title))
      .map((id, i) => [i, document.getElementById(id)] as const)
      .filter((t): t is [number, HTMLElement] => t[1] !== null);

    const observers: IntersectionObserver[] = [];

    for (const [i, element] of elements) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisibleIndexes(visibleIndexes => new Set(visibleIndexes).add(i));
          } else {
            setVisibleIndexes(visibleIndexes => {
              const newSet = new Set(visibleIndexes);
              newSet.delete(i);
              return newSet;
            });
          }
        },
        { threshold: 0.7 },
      );
      observer.observe(element);
      observers.push(observer);
    }
    return () => {
      for (const observer of observers) {
        observer.disconnect();
      }
    };
  }, [indexes]);

  return (
    <div className="w-full flex flex-col">
      <button className="flex flex-row justify-between border rounded items-center px-5 py-3 shadow-1" onClick={() => setOpen(!open)}>
        <div className="flex flex-row items-center gap-3">
          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.27454 5.73524C2.27454 4.63606 3.1656 3.745 4.26479 3.745H13.9317C15.0309 3.745 15.922 4.63606 15.922 5.73524C15.922 6.83443 15.0309 7.72549 13.9317 7.72549H4.26479C3.1656 7.72549 2.27454 6.83443 2.27454 5.73524ZM4.26479 4.88228C3.79371 4.88228 3.41182 5.26417 3.41182 5.73524C3.41182 6.20632 3.79371 6.58821 4.26479 6.58821H13.9317C14.4028 6.58821 14.7847 6.20632 14.7847 5.73524C14.7847 5.26417 14.4028 4.88228 13.9317 4.88228H4.26479ZM2.27454 10.0001C2.27454 9.68601 2.52913 9.43142 2.84318 9.43142H15.3533C15.6674 9.43142 15.922 9.68601 15.922 10.0001C15.922 10.3141 15.6674 10.5687 15.3533 10.5687H2.84318C2.52913 10.5687 2.27454 10.3141 2.27454 10.0001ZM2.27454 12.8433C2.27454 12.5292 2.52913 12.2746 2.84318 12.2746H15.3533C15.6674 12.2746 15.922 12.5292 15.922 12.8433C15.922 13.1573 15.6674 13.4119 15.3533 13.4119H2.84318C2.52913 13.4119 2.27454 13.1573 2.27454 12.8433ZM2.27454 15.6865C2.27454 15.3724 2.52913 15.1179 2.84318 15.1179H15.3533C15.6674 15.1179 15.922 15.3724 15.922 15.6865C15.922 16.0005 15.6674 16.2551 15.3533 16.2551H2.84318C2.52913 16.2551 2.27454 16.0005 2.27454 15.6865Z"
              fill="#0BBCA7"
            />
          </svg>
          <span>INDICE</span>
        </div>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx('transition-transform', open ? 'rotate-180 md:rotate-0' : 'md:rotate-180 rotate-0')}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.44194 7.56694C7.19786 7.81102 6.80214 7.81102 6.55806 7.56694L0.308058 1.31694C0.063981 1.07286 0.063981 0.677135 0.308058 0.433058C0.552136 0.18898 0.947864 0.18898 1.19194 0.433058L7 6.24112L12.8081 0.433059C13.0521 0.188981 13.4479 0.188981 13.6919 0.433059C13.936 0.677137 13.936 1.07287 13.6919 1.31694L7.44194 7.56694Z"
            fill="#07142F"
          />
        </svg>
      </button>
      <div
        className={clsx('transition-all overflow-hidden duration-700', open ? 'md:!max-h-0' : 'max-sm:!max-h-0')}
        style={{ maxHeight: `${openHeight}px` }}
      >
        <ol className="px-2.5 py-6 flex flex-col gap-0.5 overflow-hidden" ref={indexesListRef}>
          {indexes.map((title, i) => (
            <li key={i} className="overflow-hidden group">
              <Link
                className={clsx(
                  'whitespace-nowrap text-brandBlue-900 inline-block overflow-hidden w-full overflow-ellipsis transition-colors duration-300 py-3 px-2.5 rounded-lg text-sm',
                  i === firstVisibleIndex ? 'font-medium bg-primary-light-active' : 'group-hover:text-secondary-hover',
                )}
                href={`#${titleToElementId(title)}`}
                title={title}
                onClick={e => {
                  e.preventDefault();
                  document.getElementById(titleToElementId(title))?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                {title}
              </Link>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
